import React from "react";
import NordSecurity from "../../components/assets/nord_security.png";
import Gaidelis from "../../components/assets/gaidelis.svg";
import VitaInut from "../../components/assets/vinu.svg";
const SponsorsComponent = () => {
  return (
    <div className=" inline-grid justify-center mb-12  break-normal text-white text-xl font-bold font-sans">
      <div
        className="bg-gradient-to-r from-purple-800 to-green-500  mt-6 pr-4 pl-4 rounded-xl w-auto border-2 border-purple-400"
        style={{
          background:
            "linear-gradient(120deg,#722f818a 40%, rgba(3, 71, 57, 0.8) 100%)",
        }}
      >
        <div className="lg:grid lg:grid-rows-2 p-6 lg:grid-cols-3 gap-12 grid-flow-cols text-left mt-6">
          <div className="flex justify-center items-center mb-6 ">
            <img
              src={NordSecurity}
              alt="logo"
              className="cursor-pointer h-20 invertColor"
              onClick={() => {
                window.open("https://bit.ly/49Gww2l", "_blank");
              }}
            />
          </div>
          <div className="col-span-2 text-justify mb-12">
            Vienas iš Lietuvos technologijų vienaragių, kuriantis tokius
            pasaulyje pirmaujančius produktus kaip “NordVPN” ir “NordPass”. Kurk
            saugią kibernetinę ateitį kartu su „Nord Security“. Susipažink su
            laisvomis pozicijomis „Nord Security“{" "}
            <a
              className="text-teal-300 underline"
              href="https://bit.ly/49Gww2l"
              target="_blank"
              rel="noopener noreferrer"
            >
              karjeros puslapyje.
            </a>
          </div>
          <div className="flex justify-center items-center mb-6">
            <img
              src={Gaidelis}
              alt="logo"
              className="cursor-pointer h-24"
              onClick={() => {
                window.open(
                  "https://gaidelisklasika.lt/#krekeriai_description",
                  "_blank"
                );
              }}
            />
          </div>
          <div className="col-span-2  text-justify mb-12">
            Traškučiai ir kepta duona – jūs sutriuškinti! GAIDELIS žengia į
            sūraus skonio pasaulį ir pristato savo vardo krekerius. Kirsk
            GAIDELIO krekerius, kai skubi, lesk išsidrėbęs ant sofos. Susikimšk
            visą pakelį be jokios gėdos arba džiugink draugus vaišindamas.{" "}
            <a
              className="text-teal-300 underline"
              href="https://gaidelisklasika.lt/#krekeriai_description"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sužinok daugiau čia
            </a>
          </div>

          <div className="flex justify-center items-center mb-6 ">
            <img
              src={VitaInut}
              alt="logo"
              className="cursor-pointer h-20 invertColor"
              onClick={() => {
                window.open("https://vinu.org", "_blank");
              }}
            />
          </div>
          <div className="col-span-2  text-justify mb-12">
            Vita Inu yra meme coinas, turintis galimų panaudojimų realiame
            pasaulyje. Greitas, be pervedimo mokesčių ir tausojantis elektros
            energiją.{" "}
            <a
              className="text-teal-300 underline"
              href="https://vinu.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sužinok daugiau čia
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SponsorsComponent;
