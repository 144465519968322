import React, { useState, useEffect } from "react";
import { GetRequest } from "../utils/HandleRequest";

const Youtube = () => {
  const [youtubeData, setYoutubeData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const results = await GetRequest("/youtube");

      setYoutubeData(results.data);
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="container mx-auto my-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {youtubeData.map((item) => (
            <div key={item.id}>
              <p className="text-center m-8 rounded-lg text-white font-bold">{item.text_field}</p>
              <div className="border-2 border-purple-500 text-center m-8 rounded-lg text-white">
                <iframe
                  title={`YouTube Embed ${item.id}`}
                  className="w-full h-full rounded-md"
                  src={item.embed_code}
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Youtube;
