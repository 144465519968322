import React, { useState } from "react";
import PlayerTable from "../components/HomePage/PlayerTable";
import Countdown from "react-countdown";

import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";

const Home = ({ settings }) => {
  const [currentDate] = useState(new Date());

  return (
    <div className="mt-4 text-center text-white text-3xl font-bold font-sans">
      <PlayerTable />

      {Date.parse(settings.start_date) > Date.parse(currentDate) ? (
        <div className="text-white text-2xl font-bold font-sans text-center mt-10">
          FILLQ PRASIDĖS UŽ <div class="spacer"></div>
          <FlipClockCountdown
            to={Date.parse(settings.start_date)}
            labels={["DIENOS", "VALANDOS", "MINUTĖS", "SEKUNDĖS"]}
          />
        </div>
      ) : Date.parse(settings.end_date) > Date.parse(currentDate) ? (
        <>
          <div className="text-white text-2xl font-bold font-sans text-center">
            FILLQ BAIGSIS UŽ:{" "}<div class="spacer"></div>
            <FlipClockCountdown
              to={Date.parse(settings.end_date)}
              labels={["DIENOS", "VALANDOS", "MINUTĖS", "SEKUNDĖS"]}
            />
          </div>
        </>
      ) : (
        <>
          <div className="text-white text-2xl font-bold font-sans text-center mt-10">
            FILLQ SUGRĮŽTA!
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
