import React from 'react'

const Footer = () => {
    return (
        <div className="flex justify-center items-center h-8 bg-black text-white align-bottom">
            <p> Copyright © Visos teisės saugomos. </p>
        </div>
    )
}

export default Footer
