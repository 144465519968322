import React from 'react'
import Content from '../components/History/Content'

const History = () => {
  return (
    <div>
        <Content/>
    </div>
  )
}

export default History